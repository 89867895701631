export default defineI18nConfig(() => ({
  legacy: false,
  fallbackLocale: 'en',
  formatFallbackMessages: true,
  missing: (locale, key, defaultValue) => {
    // Handle missing translation here
    console.error(`Missing translation for key "${key}" in locale "${locale}"`);
    return defaultValue || key; // Return the default value or the key itself
  }
}))
